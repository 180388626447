<template>
  <div>
    <div class="ui-filterbar" v-show="showPinFilters">
      <div class="ui-filterbar__content">
        <div>
          <span>Filtri attivi:</span>
          <span class="ml-3" v-for="filter in pinFilters" :key="filter.name"
            >{{ filter.label }}: {{ filter.value }}
            <a href="#" @click.prevent="onResetPinFilter(filter)"
              ><font-awesome-icon icon="times"
            /></a>
          </span>
        </div>
        <div>
          <a href="#" @click.prevent="onResetPinFilters"
            ><font-awesome-icon icon="times" /> ANNULLA FILTRI</a
          >
        </div>
      </div>
    </div>

    <form @submit.prevent="onSearch">
      <CRow class="align-items-end">
        <CCol md="3">
          <CInput
            label="Cerca prodotti"
            :class="{
              'form-group--highlighted': hasValue(filters.keyword),
            }"
            :value="filters.keyword"
            @update:value="onSearchKeywordChange"
            placeholder="Cerca articoli, ordini, produttori, isola, id cassa"
          >
            <template #prepend-content>
              <font-awesome-icon icon="search" />
            </template>
          </CInput>
        </CCol>
        <CCol md="2">
          <UiSelect
            label="Stato articoli"
            :class="{
              'form-group--highlighted': hasValue(filters.statusId),
            }"
            :value="filters.statusId"
            :options="statusOptions"
            @update:value="onStatusSelected"
            custom
          />
        </CCol>
        <CCol md="2">
          <CSelect
            label="Conservazione"
            :class="{
              'form-group--highlighted': hasValue(filters.productTypeId),
            }"
            :value="filters.productTypeId"
            :options="productTypeOptions"
            @update:value="onProductTypeSelected"
            custom
          />
        </CCol>
        <CCol md="2">
          <CSelect
            label="Linea"
            :class="{
              'form-group--highlighted': hasValue(filters.productLineId),
            }"
            :value="filters.productLineId"
            :options="productLineOptions"
            @update:value="onProductLineSelected"
            custom
          />
        </CCol>
        <CCol col="auto">
          <CButton
            class="mb-3 mr-2"
            variant="outline"
            color="primary"
            type="submit"
          >
            CERCA
          </CButton>
          <CButton
            class="mb-3"
            variant="outline"
            color="primary"
            @click.prevent="onReset"
          >
            RESET
          </CButton>
        </CCol>
      </CRow>
    </form>
  </div>
</template>

<script>
import UiSelect from "../../../../../common/form/UiSelect";
import { mapState } from "vuex";
import EventBus from "../../../../../helpers/EventBus";
export default {
  name: "OrdersManageFilter",

  components: {
    UiSelect,
  },
  data() {
    return {
      initialized: false,
    };
  },

  computed: {
    ...mapState("orders", ["filters"]),

    statusOptions() {
      return [
        { value: "", label: "- Tutti -" },
        ...this.filters.options.status,
      ];
    },

    productLineOptions() {
      return [
        { value: "", label: "- Tutti -" },
        ...this.filters.options.productLine,
      ];
    },

    productTypeOptions() {
      return [
        { value: "", label: "- Tutti -" },
        ...this.filters.options.productType,
      ];
    },

    warehouseOptions() {
      return [
        { value: "", label: "- Tutti -" },
        ...this.filters.options.warehouse,
      ];
    },

    showPinFilters() {
      return this.$store.getters["orders/havePinFilters"];
    },

    pinFilters() {
      const filters = [];
      const labels = {
        producerId: "Produttore",
        productId: "Prodotto",
        orderId: "Ordine",
        islandId: "Isola",
      };
      const pinFilters = this.$store.getters["orders/pinFilters"];

      for (const filter in pinFilters) {
        const value = pinFilters[filter];
        if (value !== null && value.id) {
          filters.push({
            name: filter,
            label: labels[filter],
            value: value.label || value.id,
          });
        }
      }
      return filters;
    },
  },

  watch: {
    round() {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const self = this;
      const lang = this.$i18n.locale;
      const roleId = this.$store.state.role.id;

      self.initialized = false;
      this.$store
        .dispatch("orders/initFilters", {
          roleId,
          lang,
        })
        .then(() => {
          self.initialized = true;
        });
    },

    changeViewType(value) {
      this.$emit("update:viewType", value);
    },

    onSearchKeywordChange(value) {
      this.$store.commit("orders/updateKeyword", value);
    },

    onStatusSelected(value) {
      this.$store.commit("orders/updateStatusId", value);
    },

    onProductTypeSelected(value) {
      this.$store.commit("orders/updateProductTypeId", value);
    },

    onProductLineSelected(value) {
      this.$store.commit("orders/updateProductLineId", value);
    },

    onSearch() {
      EventBus.$emit("orders:applyFilters");
    },

    onResetPinFilters() {
      this.$store.commit("orders/resetPinFilters");
      this.onSearch();
    },

    onResetPinFilter(filter) {
      this.$store.commit("orders/resetPinFilter", [filter.name]);
      this.onSearch();
    },

    hasValue(value) {
      return value !== "";
    },

    onReset() {
      this.$store.commit("orders/resetMainFilters");
      this.onSearch();
    },
  },
};
</script>
