var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPinFilters),expression:"showPinFilters"}],staticClass:"ui-filterbar"},[_c('div',{staticClass:"ui-filterbar__content"},[_c('div',[_c('span',[_vm._v("Filtri attivi:")]),_vm._l((_vm.pinFilters),function(filter){return _c('span',{key:filter.name,staticClass:"ml-3"},[_vm._v(_vm._s(filter.label)+": "+_vm._s(filter.value)+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onResetPinFilter(filter)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)])})],2),_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onResetPinFilters.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}}),_vm._v(" ANNULLA FILTRI")],1)])])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}},[_c('CRow',{staticClass:"align-items-end"},[_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{class:{
            'form-group--highlighted': _vm.hasValue(_vm.filters.keyword),
          },attrs:{"label":"Cerca prodotti","value":_vm.filters.keyword,"placeholder":"Cerca articoli, ordini, produttori, isola, id cassa"},on:{"update:value":_vm.onSearchKeywordChange},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"search"}})]},proxy:true}])})],1),_c('CCol',{attrs:{"md":"2"}},[_c('UiSelect',{class:{
            'form-group--highlighted': _vm.hasValue(_vm.filters.statusId),
          },attrs:{"label":"Stato articoli","value":_vm.filters.statusId,"options":_vm.statusOptions,"custom":""},on:{"update:value":_vm.onStatusSelected}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{class:{
            'form-group--highlighted': _vm.hasValue(_vm.filters.productTypeId),
          },attrs:{"label":"Conservazione","value":_vm.filters.productTypeId,"options":_vm.productTypeOptions,"custom":""},on:{"update:value":_vm.onProductTypeSelected}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{class:{
            'form-group--highlighted': _vm.hasValue(_vm.filters.productLineId),
          },attrs:{"label":"Linea","value":_vm.filters.productLineId,"options":_vm.productLineOptions,"custom":""},on:{"update:value":_vm.onProductLineSelected}})],1),_c('CCol',{attrs:{"col":"auto"}},[_c('CButton',{staticClass:"mb-3 mr-2",attrs:{"variant":"outline","color":"primary","type":"submit"}},[_vm._v(" CERCA ")]),_c('CButton',{staticClass:"mb-3",attrs:{"variant":"outline","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onReset.apply(null, arguments)}}},[_vm._v(" RESET ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }